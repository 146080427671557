<template>
    <div class="wrap">
        <div class="box">
            <div class="top ">
                <el-carousel class="img-box" trigger="click" height="300px">
                    <el-carousel-item v-for="item in m.images" :key="item">
                        <el-image class="img" :src="rImg(item)" fit="cover" :preview-src-list="rBigImg(m.images)">
                            <div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div>
                        </el-image>
                    </el-carousel-item>
                </el-carousel>
                <div class="content">
                    <h3 class="title">{{ m.content }}</h3>
                    <div class="user" style="margin-top: 30px;" v-if="m.user">
                        <img :src="rImg(m.user.head_portrait)" :onerror="txImg" class="tx" alt="" />
                        <span class="name ellipsis-1">{{ m.user.name }}</span>
                    </div>
                    <div class="bm" v-if="m.joinUsers">
                        <img :src="rImg(p.head_portrait)" :onerror="txImg" v-for="(p, pindex) in m.joinUsers.splice(0, 4)" class="tx" alt="" />
                        <span class="num">已有{{ m.joinUsers.length }}人报名</span>
                        <i class="el-icon-arrow-right"></i>
                    </div>
                </div>
            </div>
            <ul class="info">
                <li class="list">
                    <span class="name">状态 :</span>
                    <span class="val">{{ !m.state ? '进行中' : '已结束' }}</span>
                </li>
                <li class="list">
                    <span class="name">时间 :</span>
                    <span class="val">{{ m.activity_time }}</span>
                </li>
                <li class="list">
                    <span class="name">人数 :</span>
                    <span class="val">{{ m.sizes ? '多人' : '单人' }}</span>
                </li>
                <li class="list">
                    <span class="name">对象 :</span>
                    <span class="val">{{ m.limit_uid ? '指定用户' : arr3[m.sex] }}</span>
                </li>
                <li class="list">
                    <span class="name">地点 :</span>
                    <span class="val">{{ m.address_name }}</span>
                </li>
            </ul>
            <payList v-if="m.pay" @setPay="setPay"></payList>
            <div class="bottom flex-c">
                <el-button v-if="m.state" round class="app-btn ready">活动已结束</el-button>
                <el-button v-else-if="m.user_id == myid" @click="endAct()" round class="app-btn">结束活动</el-button>
                <div v-else-if="!m.limit_uid || m.limit_uid == myid">
                    <el-button v-if="!m.is_join" round class="app-btn" @click="submit()">{{ m.pay ? '支付￥' + m.price + '报名' : '免费报名' }}</el-button>
                    <el-button v-else-if="m.joinInfo && !m.joinInfo.state" round class="app-btn" @click="confirmAct()">确认已参与该活动</el-button>
                    <el-button v-else round class="app-btn ready">已报名</el-button>
                </div>
                <el-button v-else round class="app-btn ready">不在可报名对象内</el-button>
            </div>
        </div>
        <h3 class="box-title">邀约流程</h3>
        <div class="box">
            <div class="safe-box service-box">
                <div class="safe" v-if="m.pay">
                    <p class="txt">支付金额</p>
                    <i class="iconfont icon-qianbao"></i>
                </div>
                <i class="el-icon-arrow-right" v-if="m.pay"></i>
                <div class="safe">
                    <p class="txt">发起方确认</p>
                    <i class="iconfont icon-querendan-"></i>
                </div>
                <i class="el-icon-arrow-right"></i>
                <div class="safe">
                    <p class="txt">参与方确认</p>
                    <i class="iconfont icon-querenfangan"></i>
                </div>
                <i class="el-icon-arrow-right"></i>
                <div class="safe">
                    <p class="txt">邀约完成</p>
                    <i class="iconfont icon-queren"></i>
                </div>
            </div>
        </div>
        <h3 class="box-title">平台保障</h3>
        <div class="box">
            <div class="safe-box">
                <div class="safe">
                    <i class="iconfont icon-danganziliao-ziliaoqingdan"></i>
                    <p class="txt">真实资料</p>
                    <p class="txt-des">实名认证 真实可靠</p>
                </div>
                <div class="safe">
                    <i class="iconfont icon-anquan-"></i>
                    <p class="txt">资金安全</p>
                    <p class="txt-des">平台监督 保障安全</p>
                </div>
                <div class="safe">
                    <i class="iconfont icon-kefu"></i>
                    <p class="txt">在线客服</p>
                    <p class="txt-des">在线客服 全程服务</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import payList from "@components/common/pay";
    import {
        mapGetters,
        mapMutations
    } from "vuex";
    export default {
        components: {
            payList,
        },
        data() {
            return {
                id: "",
                arr3: ["不限", "男", "女"],
                m: {},
                method: 2, // 2支付宝 3微信
                myid: "",
            };
        },
        created() {
            // 在组件实例处理完所有与状态相关的选项后调用。
            this.id = this.$route.query.id;
            this.getData();
        },
        mounted() {
            // 在组件被挂载之后调用。
        },
        methods: {
            setPay(t) {
                this.method = t;
            },
            // 获取详情
            async getData() {
                this.myid = this.$y_getKey("userid");
                let {
                    data
                } = await this.$y_ajax("api/activity/infoul", {
                    id: this.id
                });
                this.m = data;
            },
            // 参与活动
            async submit() {
                let obj = {
                    id: this.id,
                };
                if (this.m.pay) {
                    obj.paymethod = this.method;
                    this.$y_msg("该服务暂未开通");
                    return;
                }
                let ret = await this.$y_ajax("api/activity/join", obj);
                this.$y_msg(ret.msg);
                if (ret && ret.code == 200) {
                    if (!this.m.pay) {
                        this.m.is_join = 1;
                    }
                }
            },
            // 确认参与活动
            async confirmAct() {
                let ret = await this.$y_ajax("api/activity/confirm", {
                    id: this.id
                });
                this.$y_msg(ret.msg);
                if (ret && ret.code == 200) {
                    this.m.joinInfo.state = 1;
                }
            },
            // 结束活动
            async endAct() {
                let ret = await this.$y_ajax("api/activity/setOver", {
                    id: this.id
                });
                this.$y_msg(ret.msg);
                if (ret && ret.code == 200) {
                    this.m.state = 1;
                }
            },
        },
        computed: {
            // 计算属性
            ...mapGetters(["s_getUser"]),
        },
        watch: {
            // 监听
            s_getUser() {
                this.getData();
            },
        },
    };
</script>

<style lang="less" scoped>
    @import "@assets/less/des.less";

    .user {}

    .bm {
        display: flex;
        align-items: center;
        margin-top: 20px;
        margin-left: 10px;

        .tx {
            margin-left: -10px;
            width: 25px;
            height: 25px;
        }

        .num {
            margin: 0 7px;
        }
    }

    .info {
        margin-top: 30px;
        margin-left: 30px;

        .list {
            margin-bottom: 20px;

            .name {
                margin-right: 20px;
            }
        }
    }
</style>